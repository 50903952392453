import { useNavigate } from 'react-router-dom';
import GeneralLayout from '../Layouts/GeneralLayout';
import concluido from '../assets/concluido.png';
import { Button } from '../components/Button';

import '../css/concluido.css'

export default function LoginSuccess() {

    const navigate = useNavigate()

    const plan = () => {
        navigate('/plan')
    }
    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <img src={concluido} alt="" className='icon-person' />
                <div className='container-concluido'>
                    <h2 className='login-success__title'>Você foi logado com sucesso!</h2>
                    <p className='login-success___text'>Clique no botão abaixo para acessar as informações do seu plano!</p>
                </div>
            </GeneralLayout>
            <Button name="Entrar" onClick={plan} />
        </div>
    )
}