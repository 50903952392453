import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './css/global.css'

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9549cc439c0cc1fb5533704e2aef607c@o4506315653120000.ingest.sentry.io/4506315655217152",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/cadastro\.motomei\.com\//],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.0, // Capture x% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
