import '../css/input.css'
import '../css/error.css'
import { forwardRef } from 'react'
import cancel from '../assets/cancel.svg'

export default forwardRef(function Input({idPrincipal, id, label, onChange, idError, textError, idIcon, ...props }) {
    return (
        <div className="input-main" id={idPrincipal}>
            <label
                htmlFor={id}
                className="input-main__label"
            >
                   {label}
            </label>

            <input
                {...props}
                id={id}
                className='input-main__input'
                onChange={onChange}
            />
            <img src={cancel} className='input-main__icon-cancel' id={idIcon}/>
            <p className='text-information' id={idError}>{textError}</p>
        </div>
    )
});