import '../css/linkEmail.css'
import verify from '../assets/concluido.png'
import { Button } from '../components/Button'
import GeneralLayout from '../Layouts/GeneralLayout'
import { useNavigate } from 'react-router-dom'

export default function LinkEmail() {

    const navigate = useNavigate()

    const goLogin = () => {
        navigate('/')
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <div className="link-email">
                    <div className="link-email-texts">
                        <img className='link-email__img' src={verify} alt="" />
                        <p className='link-email-texts__text'>Enviamos um link de verificação para seu email.</p>
                        <p className='link-email-texts__text'>Com esse link você será direcionado para sua conta Motomei!</p>
                    </div>
                </div>
            </GeneralLayout>
            <Button name="Voltar ao login" onClick={goLogin}/>
        </div>
    )
}