import "../css/plan.css"
import img from "../assets/canceled-plan.svg"
import GeneralLayout from "../Layouts/GeneralLayout";
import { Button } from "../components/Button";
import { redirect, useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, addDoc, doc, query, where, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from "../services/firebaseConfig";
// import { useParams } from "react-router-dom";

export default function CaneceledPlan() {

    const navigate = useNavigate();
    // const { documentId } = useParams();

    const cancelAcount = async () => {
        try {
            const userDocRef = doc(firestore, userCollection, documentId);
            const userSubcollectionRef = collection(userDocRef, "motomei-subscription");

            const canceledAt = firebase.firestore.FieldValue.serverTimestamp();
            const canceledVia = "Usuário";

            await userDocRef.update({
                status: "inactive",
                canceled_at: canceledAt,
                canceled_via: canceledVia
            });

            console.log("Conta cancelada com sucesso.");
        } catch(error) {
            console.error("Erro:", error)
            return [];
        }

        navigate('/')
    }

    const goTermsUse = () => {
        let url = '/terms-use'
        window.open(url, 'blank')
    }

    return (
        <div className='wrapperGeneralLayout'>

            <GeneralLayout>
                <section className="canceled-plan">
                    <img src={img} alt="" />
                    <p className="canceled-plan__main-information">Lamentamos que esteja cancelando nossa parceria</p>
                    <p className="canceled-plan__text">
                        Entraremos em contato nos próximos dias para formalizar o encerramento. Seu plano continuará ativo até o dia 01, conforme nossos <a onClick={goTermsUse} className="canceled-plan__terms-use">Termos e Condições</a>. Esperamos retomar nossa parceria no futuro.
                    </p>
                </section>
            </GeneralLayout>
            <Button name="Voltar ao início" id={'button-cadastrar'} onClick={cancelAcount} form='in-password' />

        </div>
    )
}