import '../css/inputRadio.css'
import '../css/accountLevel.css'
import GeneralLayout from "../Layouts/GeneralLayout";
import { Button } from "../components/Button";
import StepCurrent from "../components/StepCurrent";
import StepEmpty from "../components/StepEmpty";
import StepSuccess from "../components/StepSuccess";
import Steps from "../components/Steps";
import { useNavigate, useParams } from 'react-router-dom';
import FormLayout from '../Layouts/FormLayout';
import { useState } from 'react';
import { ButtonGov } from '../components/ButtonGov';
import { doc, collection, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from '../services/firebaseConfig';

export default function AccountLevel() {
    const [enable, setEnable] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const { documentId } = useParams();

    const navigate = useNavigate();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setEnable(false);
    };

    const goInformation = () => {
        window.open("https://www.cianorte.pr.gov.br/uploads/arquivo/Casa-Empreendedor-Como-aumentar-o-nivel-da-conta-do-Gov.pdf", '_blank')
    }

    const submit = async (event) => {
        event.preventDefault();
        
        let accountLevel = null;

        if (selectedOption === 'yes') {
            accountLevel = 'ouro ou prata';
        } else if (selectedOption === 'no') {
            accountLevel = 'bronze';
        } else {
            accountLevel = 'não sabe';
        }

        try {
            console.log("Nível da conta: ", accountLevel);
            
            await updateDoc(
                doc(collection(firestore, userCollection), documentId),
                {
                    account_level: accountLevel,
                    updated_at: serverTimestamp(),
                }
            );
            
            navigate(`/gov-password/${documentId}`)
        } catch (error) {
            console.error("Erro ao salvar nível da conta: ", error);
        }
    };
    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepCurrent text="4. Nível de conta" />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                </Steps>
                <h4 className="account-level__subtitle">Para avançarmos com o seu cadastro, é necessário que a sua conta esteja no nível prata ou ouro.</h4>
                <details className='information-dropdown'>
                    <summary className='information-dropdown__question'>Entenda sobre o nível da sua conta</summary>
                    <div className="information-dropdown__content">
                        <p className='information-dropdown__text'>
                            É uma identificação que comprova em meios digitais que você é você. Com ela, você se identifica com segurança na hora de acessar serviços digitais.</p>
                        <p className='information-dropdown__text'>A conta gov.br tem três níveis - ouro, prata e bronze - que se baseiam na segurança da validação dos dados do usuário durante a criação. Quanto mais segura for a validação, mais alto o nível da sua conta.</p>
                        <ButtonGov name="Saiba mais" onClick={goInformation} />
                    </div>
                </details>
                <form className="account-level__form" onSubmit={submit} id='account-level'>
                    <p className="account-level__question">A sua conta é ouro ou prata</p>
                    <div className="account-level inputs">
                        <div className="account-level input">
                            <input
                                type="radio"
                                name="account-level"
                                id="account-level-yes"
                                value="yes"
                                checked={selectedOption === 'yes'}
                                onChange={handleOptionChange}
                            />
                            <label htmlFor="account-level-yes">Sim</label>
                        </div>
                        <div className="account-level input">
                            <input
                                type="radio"
                                name="account-level"
                                id="account-level-no"
                                value="no"
                                checked={selectedOption === 'no'}
                                onChange={handleOptionChange}
                            />
                            <label htmlFor="account-level-no">Não</label>
                        </div>
                        <div className="account-level input">
                            <input
                                type="radio"
                                name="account-level"
                                id="account-level-dont-know"
                                value="dont-know"
                                checked={selectedOption === 'dont-know'}
                                onChange={handleOptionChange}
                            />
                            <label htmlFor="account-level-dont-know">Não sei</label>
                        </div>
                    </div>
                </form>
            </GeneralLayout>
            <div className="button" style={{ opacity: enable ? '0.5' : '1' }}>
                <Button name="Continuar" form='account-level' disabled={enable} />
            </div>
        </div>
    )
}