import '../css/passwordGov.css'
import '../css/inputPassword.css'
import '../css/dropQuestion.css'
import GeneralLayout from "../Layouts/GeneralLayout";
import StepCurrent from "../components/StepCurrent";
import StepEmpty from "../components/StepEmpty";
import StepSuccess from "../components/StepSuccess";
import Steps from "../components/Steps";
import logo from '../assets/govbr-logo.svg'
import React, { useEffect } from 'react';
import visibility from '../assets/visibility.svg'
import { Button } from '../components/Button';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, collection, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from '../services/firebaseConfig';
import checkVefiry from '../assets/check-verif.svg'
import { encryptPassword } from '../../js/crypto';


export default function PasswordGov() {
    const { documentId } = useParams();

    const navigate = useNavigate();

    const goTermsUse = () => {
        let url = '/terms-use'
        window.open(url, 'blank')
    }

    const [check, setCheck] = useState(true)

    const toggleCheck = () => {
        setCheck(!check)

        if (check) {
            document.getElementById('button-cadastrar').style.backgroundColor = '#f6be01'
        } else {
            document.getElementById('button-cadastrar').style.backgroundColor = '#c5d1e2'
        }

    }

    useEffect(() => {
        if (check) {
            document.getElementById('button-cadastrar').style.backgroundColor = '#c5d1e2'
        }
    }, []);

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    let especialRegex = /\W|_/
    let numberRegex = /[0-9]+/
    let upperRegex = /([A-Z])/
    let lowerRegex = /([a-z])/

    var amount = false
    var especial = false
    var number = false
    var upper = false
    var lower = false

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            redirect();
        }
    });

    const validation = () => {
        let validationAmount = document.getElementById('validation-amount')
        let validationEspecialChar = document.getElementById('validation-especial-char')
        let validationNumber = document.getElementById('validation-number')
        let validationUpper = document.getElementById('validation-uppercase')
        let validationLower = document.getElementById('validation-lowercase')
        let validationsList = document.getElementById('validations-list')

        validationsList.style.display = 'flex'


        let inputPassword = document.getElementById('in-password')

        especialRegex.test(inputPassword.value)

        if (inputPassword.value.length > 7) {
            validationAmount.style.display = 'block'
            amount = true
        } else {
            validationAmount.style.display = 'none'
        }

        if (especialRegex.test(inputPassword.value)) {
            validationEspecialChar.style.display = 'block'
            especial = true
        } else {
            validationEspecialChar.style.display = 'none'
        }

        if (numberRegex.test(inputPassword.value)) {
            validationNumber.style.display = 'block'
            number = true
        } else {
            validationNumber.style.display = 'none'
        }

        if (upperRegex.test(inputPassword.value)) {
            validationUpper.style.display = 'block'
            upper = true
        } else {
            validationUpper.style.display = 'none'
        }

        if (lowerRegex.test(inputPassword.value)) {
            validationLower.style.display = 'block'
            lower = true
        } else {
            validationLower.style.display = 'none'
        }

    };

    async function redirect() {
        validation();
        let declarationChecked = document.getElementById('declaration-checked')

        if (check) {
            declarationChecked.style.display = 'flex'
        } else {
            declarationChecked.style.display = 'none'

            let password = document.getElementById('in-password')
            let errorInformation = document.getElementById('error-information')
            let inputEmptyInformation = document.getElementById('input-empty-information')

            if (password.value == '') {
                inputEmptyInformation.style.display = 'flex'
                errorInformation.style.display = 'none'
            } else {
                inputEmptyInformation.style.display = 'none'

                if (amount && especial && number && upper && lower) {
                    try {
                        const encryptedPassword = await encryptPassword(password.value);
                        await updateDoc(
                            doc(collection(firestore, userCollection), documentId),
                            {
                                gov_password: encryptedPassword,
                                updated_at: serverTimestamp(),
                            }
                        );
                        console.log("Senha criptografada: ", encryptedPassword);
                        navigate(`/regularization/${documentId}`);
                    } catch (error) {
                        console.error("Erro ao salvar senha: ", error);
                    }
                } else {
                    errorInformation.style.display = 'flex';
                }
            }
        }
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepCurrent text="5. Senha" />
                    <StepEmpty />
                    <StepEmpty />
                </Steps>
                <img src={logo} alt="Gov.br" className="gov-logo" />
                <h4 className='title'>
                    Precisamos que insira sua senha
                    cadastrada do aplicativo GOV.BR
                </h4>
                <details className='information-dropdown'>
                    <summary className='information-dropdown__question'>Por que precisamos da senha?</summary>
                    <div className="information-dropdown__content">
                        <p className='information-dropdown__text'>A senha do gov.br é utilizada para a criação do MEI para quem não tem CNPJ.</p>
                        <p className='information-dropdown__text'>Já para quem tem CNPJ a senha é utilizada para verificar se o usuário atende os requisitos para inclusão no projeto e, caso tenha algum dado que precisa ser ajustado, o procedimento é feito com o login e senha do GOV.</p>
                        <p className='information-dropdown__text'>A MotoMei Contabilidade se compromete com a segurança dos seus dados seguindo todos os parâmetros da LGPD.</p>
                        <p className='information-dropdown__text'>Leia nossos <a onClick={goTermsUse} className='terms-use__link'>termos de uso completos</a>.
                        </p>
                    </div>
                </details>
                <div className="error-information" id='error-information'>
                    A senha não atende aos requisitos!
                </div>
                <div className="input-empty__information" id='input-empty-information'>
                    Informe sua senha GOV
                </div>
                <div className="input-password">
                    <label htmlFor={'in-password'} className='input-password__label'>Senha</label>
                    <input
                        id='in-password'
                        className='input-password__input'
                        type={passwordShown ? "text" : "password"}
                        placeholder="••••••••••••••"
                        onKeyUp={validation}
                        maxLength={70}
                        required
                    />
                    <img
                        src={visibility}
                        alt="Visibility"
                        className='input-password__icon'
                        onClick={togglePassword}
                    />
                </div>
                <div className='password-validations'>
                    <ul className='validations-list' id='validations-list'>
                        <li className='validations-list__item' >
                            <img className='validations-list__item-img' id='validation-amount' src={checkVefiry} />
                            No mínimo 8 caractere
                        </li>
                        <li className='validations-list__item' >
                            <img className='validations-list__item-img' id='validation-especial-char' src={checkVefiry} />
                            Caractere especial (Ex: !@#)
                        </li>
                        <li className='validations-list__item'>
                            <img className='validations-list__item-img' id='validation-number' src={checkVefiry} />
                            Ao menos 1 número
                        </li>
                        <li className='validations-list__item'>
                            <img className='validations-list__item-img' id='validation-uppercase' src={checkVefiry} />
                            Ao menos 1 letra maiúscula
                        </li>
                        <li className='validations-list__item' >
                            <img id='validation-lowercase' src={checkVefiry} />
                            Ao menos 1 letra minúscula
                        </li>
                    </ul>
                </div>
                <div className="declaration">
                    <input type="checkbox" id='in-check' onClick={toggleCheck} required />
                    <label htmlFor='in-check'>
                        Declaro que li e aceito os
                        <a onClick={goTermsUse} className='terms-use__link'> termos e condições de uso</a>
                    </label>
                </div>
                <p className='declaration-checked' id='declaration-checked'>Campo obrigatório!</p>
            </GeneralLayout>
            <Button name="Cadastrar" id={'button-cadastrar'} onClick={redirect} form='in-password' />
        </div>
    )
}