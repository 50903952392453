import '../css/input.css'
import uploadIcon from '../assets/upload.svg'
import imgExample from '../assets/img-example.png'

export default function InputFile({ onChange, children }) {
    return (
        <div className='input-file'>
            <p className='input-file__text'>{children}</p>
            <img className='input-file__img' src={imgExample} alt="" />
            <label id='input-file__label' htmlFor="input-file" className='input-file__label'>
                <img className='input-file__label-img' src={uploadIcon} alt="" />
                Adicionar arquivo</label>
            <input type="file" className='input-file__input' id='input-file'
                accept='.jpg, .jpeg, .png, .svg, .pdf' required={false} onChange={onChange}/>
        </div>
    )
}