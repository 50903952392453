import '../css/debito.css'
import '../css/inputRadio.css'
import { CardWarning } from "../components/CardWarning"
import { Button } from '../components/Button';
import { useNavigate } from "react-router-dom"
import GeneralLayout from "../Layouts/GeneralLayout"
import Steps from "../components/Steps"
import StepCurrent from "../components/StepCurrent"
import StepSuccess from "../components/StepSuccess"
import { collection, getDocs, updateDoc, addDoc, doc, query, where, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from "../services/firebaseConfig"
import { useParams } from "react-router-dom"
import StepEmpty from '../components/StepEmpty';
import { useState } from 'react';

export default function Debito() {
    const navigate = useNavigate();
    const [enable, setEnable] = useState(true)

    const handleYes = () => { setEnable(false) }
    const handleNo = () => { setEnable(false) }

    const { documentId } = useParams();
    let meiRegulation = {
        mei_regulation: false
    }

    function redirect() {
        let checkYes = document.getElementById('yes')
        let checkNot = document.getElementById('not')
        let errorInformation = document.getElementById('error-information')

        if (!checkYes.checked && !checkNot.checked) {
            document.getElementById('question').style.color = '#ff0000'
            errorInformation.style.display = 'block'
        } else {
            if (checkYes.checked) {
                meiRegulation = { mei_regulation: true }
            }

            if (checkYes.checked || checkNot.checked) {
                createSubscription();
            }
            navigate(`/indicacao/${documentId}`);
        }
    }

    async function getAvailablePlanId() {
        const plansCollectionRef = collection(firestore, "motomei_plans");
        const q = query(plansCollectionRef, where("available", "==", true));

        try {
            const querySnapshot = await getDocs(q);
            const availablePlans = [];

            querySnapshot.forEach((doc) => {
                availablePlans.push(doc.id);
            });

            return availablePlans;
        } catch (error) {
            console.error("Erro ao buscar planos disponíveis: ", error);
            return [];
        }
    }

    async function createSubscription() {
        try {
            const userDocRef = doc(firestore, userCollection, documentId);
            const userSubcollectionRef = collection(userDocRef, "motomei-subscription");

            const existingSubscription = await getDocs(userSubcollectionRef);
            if (!existingSubscription.empty) {
                return;
            }

            const timestamp = serverTimestamp();
            const userAgent = navigator.userAgent;
            let clientIp = null;

            try {
                const response = await fetch("https://api64.ipify.org?format=json");
                const data = await response.json();
                clientIp = data.ip;
            } catch (error) {
                console.error("Erro ao obter o endereço IP: ", error);
            }

            const subscribedVia = JSON.stringify({
                client_ip: clientIp,
                user_agent: userAgent,
            });

            const availablePlanIds = await getAvailablePlanId();

            if (availablePlanIds.length > 0) {
                const selectedPlanId = availablePlanIds[0];

                const planData = {
                    status: "active",
                    subscribed_at: timestamp,
                    subscribed_via: subscribedVia,
                    plan_id: selectedPlanId
                };

                await addDoc(userSubcollectionRef, planData);
                console.log("Plano assinado!");
            } else {
                console.log("Nenhum plano disponível encontrado.");
            }

            try {
                await updateDoc(userDocRef, {
                    ...meiRegulation,
                    updated_at: serverTimestamp(),
                });
                console.log("Campo 'meiRegulation' adicionado ao documento do usuário.");
            } catch (error) {
                console.error("Erro ao adicionar campo ao documento do usuário: ", error);
            }

        } catch (error) {
            console.error("Erro ao criar subcoleção: ", error);
        }
    }


    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepCurrent text="6. Regularização" />
                    <StepEmpty />
                </Steps>
                <div className="texto">
                    <h3>Regularização de débitos anteriores</h3>
                    <p className="informacao">Caso você esteja com pendências no seu MEI a MotoMei pode te ajudar a solicitar a renegociação e parcelamento dos débitos.</p>
                </div>
                <div className="error-information" id='error-information'>
                    Preencha este campo
                </div>
                <div className="check">
                    <p className="pergunta" id="question">Deseja fazer a regularização?</p>
                    <div className="inputs">
                        <div className="input">
                            <input type="radio" name="govbr" id="yes" onClick={handleYes} />
                            <label htmlFor="yes">Sim</label>
                        </div>
                        <div className="input">
                            <input type="radio" name="govbr" id="not" onClick={handleNo}/>
                            <label htmlFor="not" value="Não">Não</label>
                        </div>
                    </div>
                </div>
                <CardWarning texto='Esse serviço não inclui subsídio de nenhum valor em atraso, apenas o auxílio na solicitação de parcelamento com o Governo Federal.' />
            </GeneralLayout>
            <div className="button" style={{ opacity: enable ? '0.5' : '1' }}>
                <Button name="Continuar" onClick={redirect} disabled={enable} />
            </div>
        </div>
    )
}
