import * as jose from 'https://cdnjs.cloudflare.com/ajax/libs/jose/4.14.4/index.js';

async function getPublicKey() {
  const publicKeyPem = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuI/PO+XMzKVm64UbIp1p
    VWh8S8ivdfsDVYnLx3Gttuhj4ZI33JJSqGQv0zX/YEZRDgJ3e2Tghy2a8Aj6L96Y
    TUhe7VtbtcjApNkwv/5iZhWAPPrk+GPUz8+PjYHtJgQdRVXkKtWcEt3/VOAZOKnI
    2rT5Y7VqpOW9pQc3WXuV2um71kKdx7IfvNiWf+HbmMKiOOqKsPBArg0GWi31y9Wf
    gQQp8ITgVut1Z6ySphS4zEuxvlZW4R8O78cKvrgUurFAXdrL/xI3USDEaYB9lUvv
    knaAFrAqQ216ahRXf5E08oCYidwaw93L+0kRujMJzdH75fYDia2HRhuFEFITgcoZ
    vwIDAQAB
    -----END PUBLIC KEY-----`;

  const publicKeyString = publicKeyPem.replace(/\r?\n|\r/g, '');

  return jose.importSPKI(publicKeyString, 'RSA-OAEP');
}

export async function encryptPassword(password) {
  const publicKey = await getPublicKey();

  return new jose.EncryptJWT({ password })
    .setProtectedHeader({ alg: 'RSA-OAEP', enc: 'A256GCM' })
    .setIssuedAt()
    .encrypt(publicKey);
}