import '../css/input.css'

export default function InputSelect({ id, label, value, onChange, children}) {
    return (
        <div className="input-main">
            <label
                htmlFor={id}
                className="input-main__label"
            >
                {label}
            </label>

            <select
                name=""
                id={id}
                value={value}
                onChange={onChange}
                className="input-main__select"
                placeholder='teste'
            >
                {children}
            </select>
        </div>
    )
}