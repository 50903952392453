import '../css/indicacao.css'
import { Button } from '../components/Button';
import { useNavigate } from "react-router-dom"
import GeneralLayout from "../Layouts/GeneralLayout"
import Steps from "../components/Steps"
import StepCurrent from "../components/StepCurrent"
import StepSuccess from "../components/StepSuccess"
import { useState } from "react"
import { collection, getDocs, updateDoc, addDoc, doc, query, where, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from "../services/firebaseConfig"
import { useParams } from "react-router-dom"
import Input from '../components/Input';
import StepEmpty from '../components/StepEmpty';

/**
 * @param {Function(*)} setOther
 * @return {Function(HTMLEvent)}
 */
function factory_onToggleOther(setOther) {
    return function onToggleOther(e) {
        if (!e.target.checked) {
            setOther(null);
            return;
        }

        setOther('');
    }
}

export default function Indicacao() {
    const navigate = useNavigate();
    const { documentId } = useParams();
    const [canalLeadError, setCanalLeadError] = useState();
    const [motivoInteresseError, setMotivoInteresseError] = useState();
    const [canalLeadOutros, setCanalLeadOutros] = useState(null);
    const [motivoInteresseOutros, setMotivoInteresseOutros] = useState(null);
    const [enableInputs, setEnableInputs] = useState(false);
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');

    const toggleEnableInputs = () => {
        setEnableInputs(!enableInputs);
    }

    const patterns = {
        TEL_2: /^(\d{2})(\d{5})/,
        TEL_1: /^(\d{2})/,
    }

    let telOkay = false

    //Mascara para o nome, não permite números
    function validName(event) {
        let name = event.target.value.replace(/[\d,.]+/g, "")
        in_name.value = name
    }

    //marcará para campo de telefone
    const [applyMask, setApplyMask] = useState(true);
    function maskTel(event) {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Backspace') {
                setApplyMask(!applyMask)
            }
        });
        if (applyMask) {
            let tel = event.target.value.replace(/\D+/g, "").trim()

            if (tel.length > 6) {
                tel = tel.replace(patterns.TEL_2, "($1)$2-")
            } else if (tel.length > 1) {
                tel = tel.replace(patterns.TEL_1, "($1)")
            }

            if (tel.length == 14) {
                document.getElementById('tel-error').style.display = 'none'
                telOkay = true
            } else {
                document.getElementById('tel-error').style.display = 'block'
            }

            in_phone.value = tel;
        }
    }

    async function submit(event) {
        event.preventDefault();

        const form = document.getElementById('form-indicacao');

        /** @type {HTMLInputElement[]} */
        const checked = Array.from(form.elements).filter((e) => e.checked);

        const pageData = {
            canal_lead: [],
            motivo_interesse: [],
        }

        for (const input of checked) {
            // Ignora se aparecer algum input não reconhecido
            if (pageData[input.name] == null) continue;

            pageData[input.name].push(input.value);
        }

        if (enableInputs) {
            pageData.nome_contato = document.getElementById('in_name').value;
            pageData.telefone_contato = document.getElementById('in_phone').value;
        }
        
        setCanalLeadError(!(pageData.canal_lead.length > 0));
        setMotivoInteresseError(!(pageData.motivo_interesse.length > 0));


        await savePageData(pageData);

        localStorage.clear();

        if (pageData.canal_lead.length == 0 || pageData.motivo_interesse.length == 0) {
            return;
        } else {
            navigate('/concluido')
        }
    }

    
    async function savePageData(pageData) {

        const userDocRef = doc(firestore, userCollection, documentId);

        try {
            await updateDoc(userDocRef, {
                ...pageData,
                updated_at: serverTimestamp(),
            });
            console.log("Documento do usuário atualizado com dados de indicação.");
        } catch (error) {
            console.error("Erro ao adicionar campo ao documento do usuário: ", error);
        }
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepSuccess />
                    <StepCurrent text="7. Indicação" />
                </Steps>
                <h4>Gostaríamos de saber como conheceu o MotoMei:</h4>
                <form id="form-indicacao" onSubmit={submit}>
                    <div className={'check' + (canalLeadError ? ' check--error' : '')}>
                        <div className="error-information">
                            Preencha este campo
                        </div>
                        <p className="pergunta inicio">Selecione:</p>
                        <div className="selects">
                            <label>
                                <input type="checkbox" name="canal_lead" value="app_box" />
                                App Box Delivery (Push)
                            </label>
                            <label>
                                <input type="checkbox" name="canal_lead" value="equipe" />
                                Contato com a equipe
                            </label>
                            <label>
                                <input type="checkbox" name="canal_lead" value="instagram" />
                                Instagram
                            </label>
                            <label>
                                <input type="checkbox" name="canal_lead" value="amigos" onChange={toggleEnableInputs} />
                                Indicação de amigos
                            </label>
                            <label>
                                <input type="checkbox" name="canal_lead" value={'outros:' + (canalLeadOutros ?? '')} onInput={factory_onToggleOther(setCanalLeadOutros)} />
                                Outros
                            </label>
                        </div>
                        {
                            canalLeadOutros == null ? null :
                                <div className="outros">
                                    <input
                                        type="text" name="canal_lead_outros" onInput={(e) => setCanalLeadOutros(e.target.value)}
                                        placeholder="Outros (escreva)"
                                        className="input-main__input"
                                    />
                                </div>
                        }
                    </div>
                    <div className={`form-indicacao__inputs ${enableInputs ? 'enable' : 'disable'}`}>
                        <h4 className="indicacao-inputs__title">Nos conte um pouco mais:</h4>
                        <Input
                            id="in_name"
                            label="Nome do contato:"
                            placeholder="Seu nome completo"
                            type="text"
                            onKeyUp={validName}
                            textError={"Insira o nome completo"}
                            idError={"name-error"}
                            disabled={!enableInputs}
                        />
                        <Input
                            id="in_phone"
                            label="Número de telefone:"
                            placeholder="(99)9999-9999"
                            type="text"
                            onChange={maskTel}
                            maxLength={14}
                            textError={'Telefone inválido'}
                            idError={'tel-error'}
                            disabled={!enableInputs}
                        />
                    </div>
                    <div className={'check' + (motivoInteresseError ? ' check--error' : '')}>
                        <div className="error-information" id='error-information'>
                            Preencha este campo
                        </div>
                        <p className="pergunta">Por que você se inscreveu?</p>
                        <div className="selects">
                            <label>
                                <input type="checkbox" name="motivo_interesse" value="promos" />
                                Aproveitar as promoções exclusivas
                            </label>
                            <label>
                                <input type="checkbox" name="motivo_interesse" value="beneficio_premium" />
                                Manter benefício premium
                            </label>
                            <label>
                                <input type="checkbox" name="motivo_interesse" value="regularizar_cnpj" />
                                Regularizar CNPJ
                            </label>
                            <label>
                                <input type="checkbox" name="motivo_interesse" value="beneficios_gerais" />
                                Benefícios gerais do MotoMEI
                            </label>
                            <label>
                                <input type="checkbox" name="motivo_interesse" value={'outros:' + (motivoInteresseOutros ?? '')} onInput={factory_onToggleOther(setMotivoInteresseOutros)} />
                                Outros
                            </label>
                        </div>
                        {
                            motivoInteresseOutros == null ? null :
                                <div className="outros">
                                    <input
                                        type="text" name="motivo_interesse_outros" onInput={(e) => setMotivoInteresseOutros(e.target.value)}
                                        placeholder="Outros (escreva)"
                                        className="input-main__input"
                                    />
                                </div>
                        }
                    </div>
                </form>
            </GeneralLayout>
            <Button name="Continuar" type="submit" form="form-indicacao" />
        </div>
    )
}
