import '../css/inputRadio.css'
import FormLayout from "../Layouts/FormLayout";
import GeneralLayout from "../Layouts/GeneralLayout";
import { Button } from "../components/Button";
import StepCurrent from "../components/StepCurrent";
import StepEmpty from "../components/StepEmpty";
import StepSuccess from "../components/StepSuccess";
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../components/Input';
import { useState } from 'react';
import Steps from "../components/Steps";
import { doc, collection, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore, userCollection } from '../services/firebaseConfig';



export default function FormMei() {
    const [yes, setYes] = useState(false)
    const [enable, setEnable] = useState(true)
    const [cnpj, setCnpj] = useState(null)
    const navigate = useNavigate()
    const { documentId } = useParams();

    const handleYes = () => { setYes(true), setEnable(false) }
    const handleNo = () => { setYes(false), setEnable(false) }
    const handleNoKnow = () => { setYes(false), setEnable(false) }

    const patterns = {
        CNPJ_14: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        CNPJ_8: /^(\d{2})(\d{3})(\d{3})/,
        CNPJ_5: /^(\d{2})(\d{3})/,
        CNPJ: /^(\d{2})/,
    }

    const handleCNPJ = (event) => {
        let cnpj = event.target.value.replace(/\D+/g, "").trim()

        if (cnpj.length < 2) {
            cnpj = cnpj.replace(patterns.CNPJ, '$1.');
        } else if (cnpj.length <= 5) {
            cnpj = cnpj.replace(patterns.CNPJ_5, '$1.$2');
        } else if (cnpj.length <= 8) {
            cnpj = cnpj.replace(patterns.CNPJ_8, '$1.$2.$3');
        } else if (cnpj.length <= 12) {
            cnpj = cnpj.replace(patterns.CNPJ_14, '$1.$2.$3/$4');
        }
        cnpj = cnpj.replace(patterns.CNPJ_14, '$1.$2.$3/$4-$5');

        setCnpj(cnpj);
    }

    const submit = async (event) => {
        event.preventDefault();
        
        try {
            let cnpjValue = yes ? cnpj : null;
            console.log("CNPJ: ", cnpjValue);
            
            await updateDoc(
                doc(collection(firestore, userCollection), documentId),
                {
                    cnpj: cnpjValue,
                    updated_at: serverTimestamp(),
                }
            );
            
            navigate(`/about-gov/${documentId}`)
        } catch (error) {
            console.error("Erro ao salvar cnpj: ", error);
        }
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepCurrent text="2. MEI" />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                </Steps>
                <div className="form-mei-layout">
                    <h4 className="form-mei__subtitle">Informações sobre o MEI</h4>
                    <p className='form-mei__information'>Você pode se cadastrar no MotoMei independente de ter MEI aberto ou não. </p>
                    <p className='form-mei__information'>Caso você já tenha, por gentileza:</p>
                    <ul className='form-mei__list'>
                        <li className='form-mei__list-item'>Envie o número do CNPJ abaixo. Esse dado é opcional mas pode adiantar o processo de ativação.</li>
                        <li className='form-mei__list-item'>Caso não tenha, basta marcar a opção "Não" e prosseguir que a gente abre seu CNPJ</li>
                    </ul>
                </div>
                <form className="form-mei__form" onSubmit={submit} id='form-mei'>
                    <p className="form-mei__question">Você já tem MEI aberto?</p>
                    <div className="form-mei inputs">
                        <div className="form-mei input">
                            <input type="radio" name="mei-option" id="mei-yes" value="yes" onClick={handleYes} />
                            <label htmlFor="mei-yes">Sim</label>
                        </div>
                        <div className="form-mei input">
                            <input type="radio" name="mei-option" id="mei-no" value="no" onClick={handleNo} />
                            <label htmlFor="mei-no">Não</label>
                        </div>
                        <div className="form-mei input">
                            <input type="radio" name="mei-option" id="mei-no-know" value="no-know" onClick={handleNoKnow} />
                            <label htmlFor="mei-no-know">Não sei</label>
                        </div>
                    </div>

                    <div className="option-yes" style={{
                        display: yes ? 'block' : 'none'
                    }}>
                        <Input
                            id="in_cnpj"
                            label="Insira seu CNPJ"
                            placeholder="Insira seu CNPJ"
                            onKeyUp={handleCNPJ}
                            maxLength="18"
                            type="text"
                            required={yes}
                        />
                    </div>
                </form>
            </GeneralLayout>
            <div className="button" style={{ opacity: enable ? '0.5' : '1' }}>
                <Button name="Continuar" form='form-mei' disabled={enable} />
            </div>
        </div>
    )
}