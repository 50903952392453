import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Debito from './pages/Debito';
import Form from './pages/Form';
import PlanInformation from './pages/PlanInformation';
import CheckGov from './pages/CheckGov';
import Plan from './pages/Plan';
import CriarGov from './pages/CriarGov';
import Concluido from './pages/Concluido';
import FormData from './pages/FormData';
import TermsUse from './pages/TermsUse';
import CodVerification from './pages/CodVerification';
import LinkEmail from './pages/LinkEmail';
import CaneceledPlan from './pages/CanceledPlan';
import LoginSuccess from './pages/LoginSuccess';
import Indicacao from './pages/Indicacao';
import FormMei from "./pages/FormMei";
import AboutGov from "./pages/AboutGov";
import AccountLevel from "./pages/AccountLevel";
import PasswordGov from "./pages/PasswordGov";


function App() {
  return (
    <>
      {/* Básicamente é assim que funciona, esse Route é o que chama a página que corresponte a
     um caminho na URL, dps colocamos um link pra manipular a URL
    Nesse exemplo usei um a. */}
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Navigate to='/plan-information' />} /> */}
          <Route path='/' element={<PlanInformation />} />

          {/* Formulário */}
          <Route path='/form-data' element={<FormData />} />

          <Route path='/form-mei/:documentId' element={<FormMei />} />

          <Route path='/about-gov/:documentId' element={<AboutGov />} />

          <Route path='/account-level/:documentId' element={<AccountLevel />} />
          
          <Route path='/gov-password/:documentId' element={<PasswordGov />} />
          
          <Route path='/regularization/:documentId' element={<Debito />} />
         

          
          <Route path='/form' element={<Form />} />

          <Route path='/check-gov' element={<CheckGov />} />

          <Route path='/plan/:userUid' element={<Plan />} />

          <Route path='/criar-gov' element={<CriarGov />} />

          <Route path='/indicacao/:documentId' element={<Indicacao />} />

          <Route path='/concluido' element={<Concluido />} />



          <Route path='/terms-use' element={<TermsUse />} />

          <Route path='/link-email:userUid' element={<LinkEmail />} />

          <Route path='/cod-verification/:correctFormat' element={<CodVerification />} />
          
          <Route path='/canceled-plan' element={<CaneceledPlan />} />

          <Route path='/login-success' element={<LoginSuccess />} />

        </Routes>
      </BrowserRouter>
      {/* Na pratica, e isso eu irei fazer, essa página APP tem que ser somente as routes, pra então em cada outro arquivo SEPARADO ter os conteúdos
      Do jeito que está AGORA, carrega junto com a página Teste.jsx esse conteúdo que está aqui. */}

    </>
  )
}

export default App
