import '../css/error.css'
import Steps from "../components/Steps";
import close from '../assets/close.svg'
import FormLayout from "../Layouts/FormLayout";
import GeneralLayout from "../Layouts/GeneralLayout";
import Input from "../components/Input";
import InputSelect from "../components/InputSelect";
import InputsRow from "../components/InputsRow";
import StepCurrent from "../components/StepCurrent";
import StepEmpty from "../components/StepEmpty";
import StepSuccess from "../components/StepSuccess";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import { firestore, userCollection, auth } from "../services/firebaseConfig";
import { collection, addDoc, query, where, getDocs, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword } from "firebase/auth";
import InputFile from '../components/InputFile';
import InputCheck from '../components/InputCheck';
import { useEffect, useState } from 'react';



export default function FormData() {

    const navigate = useNavigate();

    const patterns = {
        CPF_9: /^(\d{3})(\d{3})(\d{3})(\d{0,2})/,
        CPF_6: /^(\d{3})(\d{3})/,
        CPF_3: /^(\d{3})/,

        CEP: /^(\d{5})/,

        TEL_2: /^(\d{2})(\d{5})/,
        TEL_1: /^(\d{2})/,

        EMAIL: /^([\w\.\-]+)@([\w\-]+)$/,
    }

    let cpfOkay = false
    let nameOkay = false
    let cepOkay = false
    let emailOkay = false
    let telOkay = false
    let addressOkay = false
    let numberOkay = false
    let neighborhoodOkay = false
    let selectOkay = false
    let selectOkayTwo = false

    // const [applyMask, setApplyMask] = useState(true);

    //Mascara para o nome, não permite números
    function maskName() {
        let name = in_name.value.replace(/\d+/g, "")
        in_name.value = name
        let validateName = /[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ]* [a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ](.*)/.test(name)

        if (!validateName) {
            document.getElementById('name-error').style.display = 'block'
            document.getElementById('in_name').style.border = '2px solid #ff0000'
            document.getElementById('in_name').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-name').style.display = 'block'
            nameOkay = false
        } else {
            document.getElementById('name-error').style.display = 'none'
            document.getElementById('in_name').style.border = '1px solid #c5d1e2'
            document.getElementById('in_name').style.outline = 'none'
            document.getElementById('icon-cancel-name').style.display = 'none'
            nameOkay = true
        }

    }

    // Mascara pro CPF
    function maskCPF() {
        //Recebe o que vem da input e limpa se não for número
        let cpf = in_cpf.value.replace(/\D+/g, "")

        if (cpf.length > 9) {
            cpf = cpf.replace(patterns.CPF_9, "$1.$2.$3-$4")
        } else if (cpf.length > 6) {
            cpf = cpf.replace(patterns.CPF_6, "$1.$2.")
        } else if (cpf.length > 3) {
            cpf = cpf.replace(patterns.CPF_3, "$1.")
        }

        in_cpf.value = cpf
        let validateCpf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf)

        if (!validateCpf) {
            document.getElementById('cpf-error').style.display = 'block'
            document.getElementById('in_cpf').style.border = '2px solid #ff0000'
            document.getElementById('in_cpf').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-cpf').style.display = 'block'
            cpfOkay = false
        } else {
            document.getElementById('cpf-error').style.display = 'none'
            document.getElementById('in_cpf').style.border = '1px solid #c5d1e2'
            document.getElementById('in_cpf').style.outline = 'none'
            document.getElementById('icon-cancel-cpf').style.display = 'none'
            cpfOkay = true
        }
    }

    function cleanFilledEmail() {
        localStorage.removeItem('email');

        let email = in_email.value
        let validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)

        if (!validateEmail) {
            document.getElementById('email-error').style.display = 'block'
            document.getElementById('in_email').style.border = '2px solid #ff0000'
            document.getElementById('in_email').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-email').style.display = 'block'
            emailOkay = false

        } else {
            document.getElementById('email-error').style.display = 'none'
            document.getElementById('in_email').style.border = '1px solid #c5d1e2'
            document.getElementById('in_email').style.outline = 'none'
            document.getElementById('icon-cancel-email').style.display = 'none'
            emailOkay = true
        }
    }

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handlePhoneChange = (event) => {
        const inputPhoneNumber = event.target.value.replace(/\D/g, '');

        if (inputPhoneNumber.length <= 11) {
            setPhoneNumber(inputPhoneNumber);
        }

        const newIsValid = /^\d{10,11}$/.test(inputPhoneNumber);

        if (!newIsValid) {
            document.getElementById('tel-error').style.display = 'block';
            document.getElementById('in_phone').style.border = '2px solid #ff0000';
            document.getElementById('in_phone').style.outlineColor = '#ff0000';
            document.getElementById('icon-cancel-tel').style.display = 'block';
            telOkay = false;
        } else {
            document.getElementById('tel-error').style.display = 'none';
            document.getElementById('in_phone').style.border = '1px solid #c5d1e2';
            document.getElementById('in_phone').style.outline = 'none';
            document.getElementById('icon-cancel-tel').style.display = 'none';
            telOkay = true;
        }

        if (event.key === 'Backspace') {
            let phoneNumber = phoneInput.value.replace(/\D/g, '');

            if (phoneNumber.length > 0) {
                phoneNumber = '(' + phoneNumber.substring(0, 2) + ')' + phoneNumber.substring(2, 7) + '-' + phoneNumber.substring(7, 11);
            }

            phoneInput.value = phoneNumber;
            event.preventDefault();
        }

        setIsValid(newIsValid);
    };

    function applyMask(phoneNumber) {
        if (phoneNumber.length > 10) {
            let formattedNumber = '(' + phoneNumber.substring(0, 2) + ')';
            if (phoneNumber.length > 2) {
                formattedNumber += phoneNumber.substring(2, 7);
            }
            if (phoneNumber.length > 7) {
                formattedNumber += '-' + phoneNumber.substring(7, 11);
            }
            return formattedNumber;

        } else if (phoneNumber.length > 9) {
            let formattedNumber = '(' + phoneNumber.substring(0, 2) + ')';
            if (phoneNumber.length > 2) {
                formattedNumber += phoneNumber.substring(2, 6);
            }
            if (phoneNumber.length > 6) {
                formattedNumber += '-' + phoneNumber.substring(6, 10);
            }
            return formattedNumber;

        } else if (phoneNumber.length > 2) {
            let formattedNumber = '(' + phoneNumber.substring(0, 2) + ')';
            if (phoneNumber.length > 2) {
                formattedNumber += phoneNumber.substring(2, 6);
            }
            if (phoneNumber.length > 6) {
                formattedNumber += '-' + phoneNumber.substring(6, 10);
            }
            return formattedNumber;
        }

        return phoneNumber;
    };

    //Mascara do cep
    function maskCEP() {
        //Mesma coisa do cpf, não permite entrar com valores que não sejam números
        let cep = in_cep.value.replace(/\D+/g, "")

        if (cep.length == 8) {
            findCEP(cep)
        }

        if (cep.length > 5) {
            cep = cep.replace(patterns.CEP, "$1-")
        }

        in_cep.value = cep
        const validateCep = /^\d{5}-\d{3}$/.test(cep)

        if (!validateCep) {
            document.getElementById('cep-error').style.display = 'block'
            document.getElementById('in_cep').style.border = '2px solid #ff0000'
            document.getElementById('in_cep').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-cep').style.display = 'block'
            cepOkay = false

        } else {
            document.getElementById('cep-error').style.display = 'none'
            document.getElementById('in_cep').style.border = '1px solid #c5d1e2'
            document.getElementById('in_cep').style.outline = 'none'

            document.getElementById('address-error').style.display = 'none'
            document.getElementById('in_address').style.border = '1px solid #c5d1e2'
            document.getElementById('in_address').style.outline = 'none'
            document.getElementById('icon-cancel-address').style.display = 'none'

            document.getElementById('neighborhood-error').style.display = 'none'
            document.getElementById('in_neighborhood').style.border = '1px solid #c5d1e2'
            document.getElementById('in_neighborhood').style.outline = 'none'
            document.getElementById('icon-cancel-neighborhood').style.display = 'none'

            document.getElementById('in_city').style.border = '1px solid #c5d1e2'
            document.getElementById('in_state').style.border = '1px solid #c5d1e2'
            document.getElementById('icon-cancel-cep').style.display = 'none'
            cepOkay = true
        }
    }

    function findCEP(cep) {
        try {
            fetch(`https://viacep.com.br/ws/` + cep + `/json/`)
                .then(response => response.json())
                .then(data => {
                    if (data.uf === undefined && data.localidade === undefined) {
                        document.getElementById('cep-error').style.display = 'block'
                        document.getElementById('in_cep').style.border = '2px solid #ff0000'
                        document.getElementById('in_cep').style.outlineColor = '#ff0000'
                        document.getElementById('icon-cancel-cep').style.display = 'block'

                        in_city.value = ''
                        in_address.value = ''
                        in_neighborhood.value = ''
                        in_state.value = ''
                        // in_number.value = ''
                    } else {
                        cepOkay = true
                        in_city.value = data.localidade
                        in_address.value = data.logradouro
                        in_neighborhood.value = data.bairro
                        in_state.value = data.uf
                        // in_number.value = data.complemento

                    }
                })
        } catch (error) {
            console.error('Erro:', error)
        }
    }

    //Mascara do endereço
    function maskAddress() {
        const address = in_address.value

        const validateAddress = /^[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ]*[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ](.*)/.test(address)

        if (!validateAddress) {
            document.getElementById('address-error').style.display = 'block'
            document.getElementById('in_address').style.border = '2px solid #ff0000'
            document.getElementById('in_address').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-address').style.display = 'block'
            addressOkay = false

        } else {
            document.getElementById('address-error').style.display = 'none'
            document.getElementById('in_address').style.border = '1px solid #c5d1e2'
            document.getElementById('in_address').style.outline = 'none'
            document.getElementById('icon-cancel-address').style.display = 'none'
            addressOkay = true
        }
    }

    const [numero, setNumero] = useState('');
    const [isValidNumber, setIsValidNumber] = useState(false);

    function maskNumber() {
        const inputNumero = in_number.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        setNumero(inputNumero);

        if (inputNumero === '') {
            // Lógica de validação para número vazio
            document.getElementById('number-error').style.display = 'block';
            document.getElementById('in_number').style.border = '2px solid #ff0000';
            document.getElementById('in_number').style.outlineColor = '#ff0000';
            document.getElementById('icon-cancel-number').style.display = 'block';
            setIsValidNumber(false);
        } else {
            // Lógica de validação para número não vazio
            document.getElementById('number-error').style.display = 'none';
            document.getElementById('in_number').style.border = '1px solid #c5d1e2';
            document.getElementById('in_number').style.outline = 'none';
            document.getElementById('icon-cancel-number').style.display = 'none';
            setIsValidNumber(true);
        }
    }

    //Mascara do bairro
    function maskNeighborhood() {
        let neighborhood = in_neighborhood.value

        const validateNeighborhood = /^[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ]*[a-zzéúíóáèùìòàõãñêûîôâëyüïöäA-ZÉÚÍÓÁÈÙÌÒÀÕÃÑÊÛÎÔÂËYÜÏÖÄ](.*)/.test(neighborhood)

        if (!validateNeighborhood) {
            document.getElementById('neighborhood-error').style.display = 'block'
            document.getElementById('in_neighborhood').style.border = '2px solid #ff0000'
            document.getElementById('in_neighborhood').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-neighborhood').style.display = 'block'
            neighborhoodOkay = false

        } else {
            document.getElementById('neighborhood-error').style.display = 'none'
            document.getElementById('in_neighborhood').style.border = '1px solid #c5d1e2'
            document.getElementById('in_neighborhood').style.outline = 'none'
            document.getElementById('icon-cancel-neighborhood').style.display = 'none'
            neighborhoodOkay = true
        }
    }

    const handleSelectOption = (e) => {
        const selectedValue = document.getElementById('in_select').value;

        if (selectedValue != "") {
            selectOkay = true
            document.getElementById('in_select').style.border = '1px solid #c5d1e2'
            document.getElementById('in_select').style.outline = '0'
            document.getElementById('message-error').style.display = 'none'
        } else {
            selectOkay = false
            document.getElementById('in_select').style.border = '1px solid #ff0000'
            document.getElementById('in_select').style.outline = '1px solid #ff0000'
            document.getElementById('message-error').style.display = 'flex'
        }
    }

    const handleSelectOptionTwo = (e) => {
        const selectedValue = e.target.value;

        if (selectedValue != '') {
            selectOkayTwo = true
        }
    }

    function validFile(event) {

        const selectedFile = event.target.files[0];

        if (selectedFile) {
            document.getElementById('input-file__label').style.border = '1px solid #c5d1e2'
            document.getElementById('input-file__label').style.outline = '0'
            document.getElementById('message-error').style.display = 'none'
            document.getElementsByClassName('input-file__text')[0].style.display = 'none'
            document.getElementsByClassName('input-file__text')[0].innerHTML = ''


        } else {
            document.getElementById('input-file__label').style.border = '1px solid #ff0000'
            document.getElementById('input-file__label').style.outline = '1px solid #ff0000'
            document.getElementById('message-error').style.display = 'flex'
        }
    }

    async function uploadImage(file) {
        try {
            const storageRef = getStorage();
            const imageRef = ref(storageRef, `images/${file.name}`);
            await uploadBytes(imageRef, file);
            const imageUrl = await getDownloadURL(imageRef);
            return imageUrl;
        } catch (error) {
            console.error("Erro ao fazer upload da imagem:", error);
            throw error;
        }
    }

    function handleDisableButton(e) {
        e.setAttribute("disabled", true);
        e.style.backgroundColor = "#c5d1e2";
        e.innerHTML = "Aguarde...";
    }

    function handleEnableButton(e) {
        e.innerHTML = "Cheque os dados e tente novamente";

        setTimeout(() => {
            e.removeAttribute("disabled");
            e.style.backgroundColor = "#F6BE01";
            e.innerHTML = "Continuar";
        }, 3000);
    }

    function closeMessageError(e) {
        e.target.style.display = "none";
    }

    useEffect(() => {
        watchForValidation()
    }, []);

    function watchForValidation() {
        const inputs = document.querySelectorAll("input, select");

        inputs.forEach(input => {
            input.addEventListener('invalid', (event) => {
                input.classList.add('error');
                const parent = input.parentNode;
                parent.querySelector('img').style.display = 'block';
                parent.querySelector('p').style.display = 'block';
            }, false)
        })
    }


    const submit = async (e) => {
        let box = document.getElementById("box")
        let rappi = document.getElementById("rappi")
        let ifood = document.getElementById("ifood")
        let james = document.getElementById("james")
        let lalamove = document.getElementById("lalamove")
        let vuxx = document.getElementById("vuxx")
        let other = document.getElementById("other")

        e.preventDefault();

        maskName()
        maskCPF()
        maskCEP()
        cleanFilledEmail()
        maskAddress()
        maskNeighborhood()
        maskNumber()
        handleSelectOption()

        let selectCVehicle = document.getElementById('in_select')
        let messageError = document.getElementById('message-error')
        let verifyCpf = false
        let verifyName = false
        let verifyEmail = false
        let verifyTel = false
        let verifyCep = false
        let verifyAddress = false
        let verifyNumber = false
        let verifyNeighborhood = false
        let verifySelect = false
        let verifyCheck = false
        let verifyFileImage = false
        let buttonSubmit = document.querySelector('.button-footer__button');
        let filledEmail = localStorage.getItem('email');
        let filledUid = localStorage.getItem('uid');

        handleDisableButton(buttonSubmit);

        if (!nameOkay) {
            document.getElementById('name-error').style.display = 'block'
            document.getElementById('in_name').style.border = '2px solid #ff0000'
            document.getElementById('in_name').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-name').style.display = 'block'
            verifyName = false

        } else { verifyName = true }

        if (!cpfOkay) {
            document.getElementById('cpf-error').style.display = 'block'
            document.getElementById('in_cpf').style.border = '2px solid #ff0000'
            document.getElementById('in_cpf').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-cpf').style.display = 'block'
            verifyCpf = false

        } else { verifyCpf = true }

        if (!emailOkay) {
            document.getElementById('email-error').style.display = 'block'
            document.getElementById('in_email').style.border = '2px solid #ff0000'
            document.getElementById('in_email').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-email').style.display = 'block'
            verifyEmail = false

        } else { verifyEmail = true }

        if (!isValid) {
            document.getElementById('tel-error').style.display = 'block'
            document.getElementById('in_phone').style.border = '2px solid #ff0000'
            document.getElementById('in_phone').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-tel').style.display = 'block'
            verifyTel = false

        } else { verifyTel = true }

        if (!cepOkay) {
            document.getElementById('cep-error').style.display = 'block'
            document.getElementById('in_cep').style.border = '2px solid #ff0000'
            document.getElementById('in_cep').style.outlineColor = '#ff0000'
            document.getElementById('in_city').style.border = '2px solid #ff0000'
            document.getElementById('in_state').style.border = '2px solid #ff0000'
            document.getElementById('icon-cancel-cep').style.display = 'block'
            verifyCep = false

        } else {
            verifyCep = true
        }

        if (!addressOkay) {
            document.getElementById('address-error').style.display = 'block'
            document.getElementById('in_address').style.border = '2px solid #ff0000'
            document.getElementById('in_address').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-address').style.display = 'block'
            verifyAddress = false

        } else { verifyAddress = true }

        if (!isValidNumber) {
            document.getElementById('number-error').style.display = 'block'
            document.getElementById('in_number').style.border = '2px solid #ff0000'
            document.getElementById('in_number').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-number').style.display = 'block'
            verifyNumber = false

        } else { verifyNumber = true }

        if (!neighborhoodOkay) {
            document.getElementById('neighborhood-error').style.display = 'block'
            document.getElementById('in_neighborhood').style.border = '2px solid #ff0000'
            document.getElementById('in_neighborhood').style.outlineColor = '#ff0000'
            document.getElementById('icon-cancel-neighborhood').style.display = 'block'
            verifyNeighborhood = false
            handleEnableButton(buttonSubmit);

        } else { verifyNeighborhood = true }

        if (!selectOkay) {
            selectCVehicle.style.border = '1px solid #ff0000'
            selectCVehicle.style.outline = '1px solid #ff0000'
            messageError.style.display = 'flex'
            verifySelect = false
            handleEnableButton(buttonSubmit);

        } else {
            selectCVehicle.style.border = '1px solid #c5d1e2'
            selectCVehicle.style.outline = '0'
            messageError.style.display = 'none'
            verifySelect = true
        }

        if (box.checked || rappi.checked || ifood.checked || james.checked
            || lalamove.checked || vuxx.checked || other.checked) {

            messageError.style.display = "none"
            document.querySelector('.input-check__label').style.color = '#3A4748'
            verifyCheck = true
            handleDisableButton(buttonSubmit);
        } else {
            document.querySelector('.input-check__label').style.color = '#ff0000'
            messageError.style.display = "flex"
            verifyCheck = false
            handleEnableButton(buttonSubmit);
        }

        let imageUrl;

        const selectedImage = document.getElementById("input-file").files[0];

        //bytes
        const fileSizeLimit = 10485760;

        let imageSize= 0;
        if (selectedImage) {

            if(selectedImage.size < fileSizeLimit) {
                imageUrl = await uploadImage(selectedImage);
                document.getElementById('input-file__label').style.border = '1px solid #c5d1e2'
                document.getElementById('input-file__label').style.outline = '0'
                document.getElementsByClassName('input-file__text')[0].style.display = 'none'
                verifyFileImage = true;

            } else {
                imageSize = (selectedImage.size / 1024 / 1024).toFixed(2) + 'MB';
                document.getElementById('input-file__label').style.border = '1px solid #ff0000'
                document.getElementById('input-file__label').style.outline = '1px solid #ff0000'
                document.getElementsByClassName('input-file__text')[0].style.display = 'block'
                document.getElementsByClassName('input-file__text')[0].innerHTML = 'A imagem enviada possui: '+ imageSize +'. A imagem deve ter no máximo 10MB'
                document.getElementsByClassName('input-file__text')[0].style.color = '#ff0000'
                verifyFileImage = false;
                handleEnableButton(buttonSubmit);
            }
            
        } else {
            document.getElementById('input-file__label').style.border = '1px solid #ff0000'
            document.getElementById('input-file__label').style.outline = '1px solid #ff0000'
            document.getElementsByClassName('input-file__text')[0].style.display = 'block'
            verifyFileImage = false;
        }

        if(!verifyFileImage) {
            handleEnableButton(buttonSubmit);
        }

        const selectedOptions = [];
        const checkboxes = document.querySelectorAll(".input-check__group input[type='checkbox']");
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                if (checkbox.id === 'other') {
                    const otherInput = document.querySelector(".input-check__group-input");
                    selectedOptions.push(otherInput.value);
                } else {
                    selectedOptions.push(checkbox.id);
                }
            }
        });

        if (verifyCpf && verifyName && verifyEmail && verifyCep && verifySelect && verifyCheck && verifyFileImage) {
            const email = document.getElementById('in_email').value;
            const tempPassword = "tempPassword";

            const emailExists = query(collection(firestore, userCollection), where("email", "==", email));
            const emailExistsSnapshot = await getDocs(emailExists);
            let alreadyRegisteredEmail = document.getElementById('email-already-registered')

            if (!emailExistsSnapshot.empty) {
                console.error("Email já existe no banco de dados");
                alreadyRegisteredEmail.style.display = 'flex'
                document.getElementById('email-error').style.display = 'block'
                document.getElementById('in_email').style.border = '2px solid #ff0000'
                document.getElementById('in_email').style.outlineColor = '#ff0000'
                document.getElementById('icon-cancel-email').style.display = 'block'
                handleEnableButton(buttonSubmit);
                return;
            }
            alreadyRegisteredEmail.style.display = 'none'


            const cpf = document.getElementById('in_cpf').value;

            const cpfExistsQuery = query(collection(firestore, userCollection), where("cpf", "==", cpf));
            const cpfExistsSnapshot = await getDocs(cpfExistsQuery);
            let alreadyRegisteredCpf = document.getElementById('cpf-already-registered')

            if (!cpfExistsSnapshot.empty) {  
                console.error("CPF já existe no banco de dados");
                alreadyRegisteredCpf.style.display = 'flex'
                document.getElementById('cpf-error').style.display = 'block'
                document.getElementById('in_cpf').style.border = '2px solid #ff0000'
                document.getElementById('in_cpf').style.outlineColor = '#ff0000'
                document.getElementById('icon-cancel-cpf').style.display = 'block'
                handleEnableButton(buttonSubmit);
                return;
            }
            alreadyRegisteredCpf.style.display = 'none'

            var user = null;

            if (!filledEmail) {
                createUserWithEmailAndPassword(auth, email, tempPassword)
                    .then(async (userCredential) => {
                        user = userCredential.user;

                        if (user) {
                            localStorage.setItem('uid', user.uid);
                            localStorage.setItem('email', user.email);
                        }

                        const formDatas = {
                            cpf: document.getElementById('in_cpf').value,
                            name: document.getElementById('in_name').value,
                            email: document.getElementById('in_email').value,
                            cep: document.getElementById('in_cep').value,
                            address: document.getElementById('in_address').value,
                            neighborhood: document.getElementById('in_neighborhood').value,
                            city: document.getElementById('in_city').value,
                            state: document.getElementById('in_state').value,
                            phone: document.getElementById('in_phone').value,
                            number: document.getElementById('in_number').value,
                            complement: document.getElementById('in_complement').value,
                            vehicle: document.getElementById('in_select').value,
                            image: imageUrl,
                            selectedOptions: selectedOptions,
                            foto_documento: imageUrl,
                            apps_usados: selectedOptions,
                            created_at: serverTimestamp(),
                            updated_at: serverTimestamp(),
                            uid: !user ? filledUid : user.uid,
                        };

                        try {

                            const docRef = await addDoc(collection(firestore, userCollection), formDatas);
                            const documentId = docRef.id;

                            navigate(`/form-mei/${documentId}`);
                        } catch (error) {
                            console.error("Erro ao salvar dados no banco: ", error);
                            handleEnableButton(buttonSubmit);
                        }
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        handleEnableButton(buttonSubmit);
                    })
            }
        }
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepCurrent text="1. Dados" />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                </Steps>
                <h4 className="subtitle">Primeiro precisamos saber algumas informações</h4>
                <div className="message-error" id="message-error">
                    <img src={close} alt="" />
                    Preencha todos os campos!
                </div>
                <div className="message-error" id="cpf-already-registered" onClick={closeMessageError}>
                    <img src={close} alt="" />
                    Este CPF já está sendo utilizado!
                </div>
                <div className="message-error" id="email-already-registered" onClick={closeMessageError}>
                    <img src={close} alt="" />
                    Este email já está sendo utilizado!
                </div>
                <FormLayout onSubmit={submit} id={'form-data'}>
                    <Input
                        id="in_name"
                        label="Nome completo"
                        placeholder="Seu nome completo"
                        type="text"
                        onKeyUp={maskName}
                        textError={"Insira seu nome completo"}
                        idError={"name-error"}
                        idIcon={"icon-cancel-name"}
                        required={false}
                    />
                    <Input
                        id="in_cpf"
                        label="CPF"
                        placeholder="999.999.999-99"
                        type="text"
                        onKeyUp={maskCPF}
                        maxLength={14}
                        textError={"CPF inválido"}
                        idError={'cpf-error'}
                        idIcon={'icon-cancel-cpf'}
                        required={false}
                    />
                    <Input
                        id="in_email"
                        label="E-mail"
                        placeholder="usuario@email.com"
                        type="email"
                        onChange={cleanFilledEmail}
                        textError={'E-mail inválido'}
                        idError={'email-error'}
                        idIcon={'icon-cancel-email'}
                        required={false}
                    />

                    <Input
                        id="in_phone"
                        label="Telefone"
                        placeholder="(99)9999-9999"
                        type="text"
                        value={applyMask(phoneNumber)}
                        onChange={handlePhoneChange}
                        maxLength={14}
                        textError={'Telefone inválido'}
                        idError={'tel-error'}
                        idIcon={'icon-cancel-tel'}
                        required={false}
                    />

                    <Input
                        id="in_cep"
                        label="CEP"
                        placeholder="00000-000"
                        type="text"
                        onKeyUp={maskCEP}
                        maxLength={9}
                        textError={'CEP inválido'}
                        idError={'cep-error'}
                        idIcon={'icon-cancel-cep'}
                        required={false}
                    />

                    <Input
                        id="in_address"
                        label="Endereço"
                        placeholder="Rua, Avenida, etc"
                        type="text"
                        onKeyUp={maskAddress}
                        textError={'Endereço inválido'}
                        idError={'address-error'}
                        idIcon={'icon-cancel-address'}
                        required={false}
                    />

                    <Input
                        id="in_number"
                        label="Número"
                        placeholder="999"
                        type="text"
                        value={numero}
                        onChange={maskNumber}
                        maxLength={6}
                        textError={'Número inválido'}
                        idError={'number-error'}
                        idIcon={'icon-cancel-number'}
                        required={false}
                    />

                    <Input
                        id="in_neighborhood"
                        label="Bairro"
                        placeholder="Bairro"
                        type="text"
                        onKeyUp={maskNeighborhood}
                        textError={'Bairro inválido'}
                        idError={'neighborhood-error'}
                        idIcon={'icon-cancel-neighborhood'}
                        required={false}
                    />

                    <Input
                        id="in_city"
                        label="Cidade"
                        placeholder="Cidade"
                        type="text"
                        disabled
                    />

                    <Input
                        id="in_state"
                        label="Estado"
                        placeholder="Estado"
                        type="text"
                        disabled
                    />

                    <Input
                        id="in_complement"
                        label="Complemento"
                        placeholder="Casa"
                        type="text"
                    />

                    <InputSelect
                        id="in_select"
                        label="Tipo do veículo"
                        onChange={handleSelectOption}
                    >
                        <option value="">Selecione uma opção</option>
                        <option value="bike">Bike</option>
                        <option value="motocicleta">Motocicleta</option>
                        <option value="carro">Carro</option>
                        <option value="vuc">VUC</option>
                        <option value="toco">Caminhão Toco</option>
                        <option value="truck">Caminhão Truck</option>
                        <option value="carreta">Carreta</option>
                        <option value="facilitador">Sou facilitador</option>
                    </InputSelect>


                    <InputCheck label={"Qual APP você usa regularmente?"}>
                        <div className="input-check__group">
                            <input type="checkbox" id='box' /> <label className='input-check__group-label' htmlFor="box">Box Delivery</label>
                        </div>
                        <div className="input-check__group">
                            <input type="checkbox" id='rappi' /> <label className='input-check__group-label' htmlFor="rappi">Rappi</label>
                        </div> <div className="input-check__group">
                            <input type="checkbox" id='ifood' /> <label className='input-check__group-label' htmlFor="ifood">IFood</label>
                        </div>
                        <div className="input-check__group">
                            <input type="checkbox" id='james' /> <label className='input-check__group-label' htmlFor="james">James</label>
                        </div>
                        <div className="input-check__group">
                            <input type="checkbox" id='lalamove' /> <label className='input-check__group-label' htmlFor="lalamove">Lalamove</label>
                        </div>
                        <div className="input-check__group">
                            <input type="checkbox" id='vuxx' /> <label className='input-check__group-label' htmlFor="vuxx">VUXX</label>
                        </div>
                        <div className="input-check__group">
                            <input type="checkbox" id="other" />
                            <label htmlFor="other" className='input-check__group-label'>Outro:</label>
                            <input type="text" className='input-check__group-input' />
                        </div>

                    </InputCheck>

                    <InputFile
                        onChange={validFile}
                        textError={'O arquivo enviado deve ter no máximo 1MB'}
                        idError={"upload-error"}
                    >
                        Envie uma cópia do seu <b className='bold'>RG</b> ou <b className='bold'>CNH</b> em arquivo de imagem ou PDF aberto e na mesma foto conforme modelo abaixo
                    </InputFile>
                </FormLayout>
            </GeneralLayout>
            <Button name="Continuar" form='form-data' />
        </div>
    )
}