import '../css/codVerification.css'
import logo from '../assets/motomei-logo.svg'
import Input from '../components/Input'
import arrow from '../assets/arrow-forward.svg'
import React, { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { auth } from '../services/firebaseConfig';

export default function CodVerification() {
    const navigate = useNavigate();
    const [verificationCode, setVerificationCode] = useState('');
    const location = useLocation(); 

    const handleVerification = async (e) => {
        e.preventDefault();

        try {
            const confirmationResult = JSON.parse(localStorage.getItem('confirmationResult'));

            if (!confirmationResult) {
                console.error('confirmationResult não está definido.');
                return;
            }
            
            const authCredential = PhoneAuthProvider.credential(
                confirmationResult.verificationId,
                verificationCode
            );

            const userCredential = await signInWithCredential(auth, authCredential);

            const user = userCredential.user;
            console.log('Usuário logado:', user);

            navigate(`/plan/${user.uid}`);
        } catch (error) {
            console.error('Erro ao verificar o código:', error);
        }
    }

    const goLogin = () => {
        navigate('/')
    }

    return (
        <>
            <div className='forgot__logo'>
                <img src={logo} alt="logo motomei" />
            </div>

            <section className='cod-verification'>
                <div className="cod-verification__introduction">
                    <h3>Código de verificação</h3>
                    <p className='cod-verification__text'>
                        Enviamos o código de verificação para o email ou telefone cadastrado!
                    </p>
                </div>
                <form className='cod-verification__form' onSubmit={handleVerification}>
                    <Input
                        id="recaptcha-container"
                        label="Código de verificação"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                    />

                    <button className='cod-verification__button'>Entrar<img src={arrow} className='cod-verification__button-icon' alt="Enviar as instruções" /></button>
                </form>

                <a className='cod-verification__link' onClick={goLogin}>Voltar</a>
            </section>
        </>
    )

}