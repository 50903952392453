import '../css/aboutGov.css'
import GeneralLayout from "../Layouts/GeneralLayout";
import StepCurrent from "../components/StepCurrent";
import StepEmpty from "../components/StepEmpty";
import StepSuccess from "../components/StepSuccess";
import Steps from "../components/Steps";
import { Button } from '../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGov } from "../components/ButtonGov";

export default function AboutGov() {
    const navigate = useNavigate()
    const { documentId } = useParams();

    const goGOV = () => {
        window.open("https://www.gov.br/pt-br", '_blank')
    }

    const goAccountLevel = () => {
        navigate(`/account-level/${documentId}`)
    }

    return (
        <div className='wrapperGeneralLayout'>
            <GeneralLayout>
                <Steps>
                    <StepSuccess />
                    <StepSuccess />
                    <StepCurrent text="3. Sobre o GOV" />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                    <StepEmpty />
                </Steps>
                <h4 className="about-gov__subtitle">Para avançar no cadastro, é necessário ter em mãos algumas informações da sua conta gov.br, como:</h4>
                <ul className="about-gov__list">
                    <li className="about-gov__item">O nível da conta gov.br</li>
                    <li className="about-gov__item">Senha da conta GOV</li>
                </ul>
                <details className="information-dropdown">
                    <summary className='information-dropdown__question'>Sobre a conta gov.br</summary>
                    <div className="information-dropdown__content">
                        <p className='information-dropdown__text'>A conta gov.br é uma identificação que comprova em meios digitais que você é você. Com ela, você se identifica com segurança na hora de acessar serviços digitais. Ela é gratuita e está disponível para todos os cidadãos brasileiros.</p>
                        <p className='information-dropdown__text'>Ao clicar no botão abaixo você será redirecionado ao site gov.br, ao digitar seu CPF você saberá se tem uma conta. Caso não tenha, faça o seu cadastro. É bem simples e rápido.</p>
                        <ButtonGov name="Acessar" onClick={goGOV}/>
                    </div>
                </details>
            </GeneralLayout>
            <Button name="Continuar" onClick={goAccountLevel}/>
        </div>
    )
}