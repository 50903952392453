import '../css/checkgov.css';
import { ButtonGov } from '../components/ButtonGov';
import GeneralLayout from "../Layouts/GeneralLayout"
import Steps from '../components/Steps';
import StepSuccess from '../components/StepSuccess';
import StepEmpty from '../components/StepEmpty';
import StepCurrent from '../components/StepCurrent';
import iconGov from '../assets/govbr-logo.svg'
import { useNavigate } from 'react-router-dom';

export default function CheckGov() {

  let url = "https://www.gov.br/pt-br"
  function redirect() {
    window.open(url, "blank")
  }

  const navigate = useNavigate()

  const goBack = () => {
    navigate('/form')
  }

  return (
    <>
      <GeneralLayout>
        <Steps>
          <StepSuccess />
          <StepCurrent text="2. GOV.BR" />
          <StepEmpty />
          <StepEmpty />
          <StepEmpty />
        </Steps>
        <img src={iconGov} alt="" className='icon-gov' />
        <div className='containerForm'>
          <h2 className='gov_question'>O que é a conta gov.br?</h2>
          <article>
            <p>
              A conta gov.br é uma identificação que comprova em meios digitais que você é você. Com ela, você se identifica com segurança na hora de acessar serviços digitais. Ela é gratuita e está disponível para todos os cidadãos brasileiros.
            </p>

            <p>
              Ao clicar no botão abaixo você será redirecionado ao site gov.br, ao digitar seu CPF você saberá se tem uma conta. Caso não tenha, faça o seu cadastro. É bem simples e rápido.
            </p>
          </article>
        </div>
        <ButtonGov name="Acessar" onClick={redirect} />
        <a onClick={goBack}>Voltar</a>
      </GeneralLayout>
    </>
  )
}
