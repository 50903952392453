import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBRANisZrhAoRjmQpRClH0fKyRk2_-hn08",
    authDomain: "motomei-database.firebaseapp.com",
    projectId: "motomei-database",
    storageBucket: "motomei-database.appspot.com",
    messagingSenderId: "375624605562",
    appId: "1:375624605562:web:9ac80d8125462dfe3cc0c3",
    measurementId: "G-KXT6L6DR92"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
auth.appVerificationDisabledForTesting = true;

const dbSuffix = (import.meta.env.VITE_DB_SUFFIX ?? '');
export const userCollection = 'user-data' + dbSuffix;

if (dbSuffix) {
    console.log('Usando o banco de dados:', userCollection);
}

export { app, analytics, firestore, storage, auth };
