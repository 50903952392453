import '../css/form.css';
import '../css/error.css'
import { Button } from '../components/Button';
import GeneralLayout from "../Layouts/GeneralLayout"
import Steps from '../components/Steps';
import StepCurrent from '../components/StepCurrent';
import StepEmpty from '../components/StepEmpty';
import { useNavigate } from 'react-router-dom';

export default function Form() {

  const navigate = useNavigate()

  function redirect() {
    let objMei = document.querySelector('input[name="mei"]:checked');
    let meiValue = '';
    if (objMei) {
      meiValue = objMei.value;
    }

    let objGov = document.querySelector('input[name="govbr"]:checked');
    let govbrValue = '';
    if (objGov) {
      govbrValue = objGov.value;
    }

    let firstValidateError = document.getElementById('first-validate')
    let secondValidateError = document.getElementById('second-validate')

    if (meiValue == "" && govbrValue == "") {
      firstValidateError.style.display = 'block'
      secondValidateError.style.display = 'block'
    } else {

      if (meiValue != "sim" && meiValue != "nao") {
        firstValidateError.style.display = 'block'
      } else {
        firstValidateError.style.display = 'none'
        if (govbrValue === "nao") {
          navigate('/criar-gov')
        } else if (govbrValue === "naosei") {
          navigate('/check-gov')
        } else if (govbrValue === "sim") {
          navigate('/form-data')
        } else {
          secondValidateError.style.display = 'block'
        }
      }
    }
  }
  return (
    <>
      <div className='wrapperGeneralLayout'>
        <GeneralLayout>
          <Steps>
            <StepCurrent text="1. MEI" />
            <StepEmpty />
            <StepEmpty />
            <StepEmpty />
            <StepEmpty />
          </Steps>
          <div className='containerForm'>
            <h3>Primeiro precisamos saber algumas informações</h3>
            <form className='form__form'>
              <div className='form__label' id='select-mei'>
                Você já tem MEI aberto?
                <div className='form__input'>
                  <input
                    type="radio"
                    id='has-mei-yes'
                    name="mei"
                    value="sim"
                  />

                  <label htmlFor="has-mei-yes">Sim</label>
                  <input
                    type="radio"
                    name="mei"
                    value="nao"
                    id='has-mei-no'

                  />
                  <label htmlFor="has-mei-no">Não</label>
                </div>
                <p className='text-information' id='first-validate'>Preencha o campo para prosseguir!</p>
              </div>
              <div className='form__label' id='select-gov'>
                Você possui cadastro no aplicativo gov.br?
                <div className='form__input'>
                  <input type="radio" name="govbr" value="sim" id='has-register-yes' />
                  <label htmlFor="has-register-yes">Sim</label>
                  <input type="radio" name="govbr" value="nao" id='has-register-no' />
                  <label htmlFor="has-register-no">Não</label>
                  <input type="radio" name="govbr" value="naosei" id='has-register-maybe' />
                  <label htmlFor="has-register-maybe">Não sei</label>
                </div>
                <p className='text-information' id='second-validate'>Preencha o campo para prosseguir!</p>
              </div>
            </form>
          </div>
        </GeneralLayout>
        <Button name="Continuar" onClick={redirect} />
      </div>
    </>
  )
}
