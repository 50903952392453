import '../css/termsUse.css'
import arrow from '../assets/arrow.svg'
import GeneralLayout from "../Layouts/GeneralLayout";


export default function TermsUse() {
    return (
        <>
            <div className='wrapperGeneralLayout'>
                <GeneralLayout>
                    <h2 className="terms_title">Termo de Adesão MotoMei</h2>
                    <div className="terms-paragraph">
                        <p className="paragraph_clause">
                            1. <span className="paragraph_clause-title">
                                Resoluções Gerais:
                            </span>
                            Estes termos tem por objetivo esclarecer as regras do programa  MotoMei, uma parceria entre MotoMei contabilidade e Box Delivery.

                        </p>
                        <p className="paragraph_subclause">
                            a. <span className="paragraph_clause-title">Sobre o programa:</span>
                            O programa MotoMei é uma parceria firmada entre a Box  Delivery Desenvolvimento de Software LTDA. e ao MotoMei Contabilidade LTDA.  Esse programa é um serviço, prestado em totalidade pela MotoMei Contabilidade  para oferecer serviços de contabilidade para entregadores vinculados à Box  Delivery.

                        </p>
                        <p className="paragraph_subclause">
                            b. <span className="paragraph_clause-title">Sobre os serviços oferecidos:</span>
                            Estão compreendidos nos serviços oferecidos pela  MotoMei Contabilidade a abertura do CNPJ na modalidade MEI (Micro  Empreendedor Individual), a emissão mensal de Notas Fiscais de Serviço em nome  do entregador para Box Delivery, indicando em sua totalidade os ganhos realizados  por aquele entregador no período do primeiro ao último dia do mês anterior, a  declaração de débitos anual do MEI, e dúvidas gerais sobre contabilidade. Qualquer  serviço não descrito acima, poderá ter um custo adicional a ser consultado  diretamente com a MotoMei Contabilidade, não competindo à Box Delivery  nenhuma negociação em nome do usuário do serviço.
                        </p>
                        <p className="paragraph_clause">
                            2. <span className='paragraph_clause-title'>Sobre a relação com a Box Delivery:</span> A Box Delivery se relaciona com este programa no  caráter de oferecimento dos serviços aos parceiros por meio do aplicativo BOX DELIVERY  ENTREGADOR e não é diretamente responsável por nenhum serviço prestado pela MotoMei  Contabilidade e terceiras que venham ocasionalmente a serem contratadas para realização  dos serviços descritos. Sendo assim, a Box Delivery se isenta de responsabilidade na  prestação deste serviço e demais serviços oferecidos no portfólio deste programa. Todavia  a Box Delivery se prontifica a fiscalizar a correta realização dos serviços prestados para a  manutenção ou não da parceria e oferecimento do serviço aos usuários do App BOX  DELIVERY ENTREGADOR.
                        </p>
                        <p className="paragraph_clause">
                            3. <span className="paragraph_clause-title"> Da aptidão do usuário à participação no programa: </span> Para estar apto para participação no  programa o usuário se compromete a fornecer informações verídicas que serão utilizadas  apenas para fins próprios do programa. Além disso ele deve ser um usuário ativo da  aplicação da Box Delivery e estar com todos os seus documentos de cadastro na Box  Delivery atualizados. Ademais, o usuário deve estar de acordo com estes termos.
                        </p>

                        <p className="paragraph_clause">
                            4. <span className="paragraph_clause-title"> Sobre autorização contábil: </span>O usuário autoriza a MotoMei contabilidade a dar entrada e  gerenciar seu CNPJ e todo e qualquer procedimento que envolva a gestão do CNPJ do  usuário. Qualquer outro serviço contábil não compreendido no escopo do projeto deve ter  autorização prévia, por escrito, do usuário e está sujeito à valores extras, conforme descrito  no item 1 parágrafo “b”.

                        </p>

                        <p className="paragraph_clause">
                            5. <span className="paragraph_clause-title"> Sobre autorização de emissão de Notas Fiscais: </span> Ao contratar, o usuário autoriza a MotoMei  Contabilidade a emitir Notas Fiscais de Serviços de entrega, com o compromisso que esta  nota será apenas no valor do faturamento que teve com a Box Delivery no período de 1  (um) mês, ou proporcional dentro deste período. A MotoMei Contabilidade se compromete  a enviar uma cópia desta Nota Fiscal para o E-mail indicado no cadastro em até 30 (trinta)  dias da emissão.

                        </p>

                        <p className="paragraph_clause">
                            6. <span className="paragraph_clause-title"> Sobre subsídio de impostos: </span> O programa MotoMei oferece, em comum acordo entre as  partes, o subsídio do imposto chamado Guia DAS, o imposto único pago por quem tem CNPJ  na modalidade MEI. O cálculo do valor do DAS ou Documento de Arrecadação do Simples  Nacional corresponde aos critérios abaixo:

                        </p>
                        <p className="paragraph_subclause">
                            a. <span className="paragraph_clause-title"> Sobre a classificação: </span> O projeto MotoMei tem duas classificações distintas, que são  elas: O MotoMei comum, no qual se enquadram usuários que realizem serviços nos  modais bicicleta, motocicleta, veículo de passeio, fiorino, VUC e similares. E a  classificação “MEI Caminhoneiro” que compreende veículos pesados com  classificação acima das categorias acima.
                        </p>
                        <p className="paragraph_subclause">
                            b. <span className="paragraph_clause-title">Sobre o valor do imposto devido por categoria: </span> A categoria <b> MEI comum </b> e pagam  a Guia DAS com o valor de 5% do salário-mínimo em vigor, somado a taxa de R$  1,00 de ICMS para comércio e indústria ou R$ 5,00 para serviços – ISS ou 6,00 para  Comércio e Serviços - ICMS e ISS. O caso deste projeto, a classificação é de serviços.  e o <b> MEI Caminhoneiro </b> (caminhões e carretas) por mês o valor equivalente a 12%  de salário mínimo somado a 1 real de ICMS e 5 reais de ISS.
                        </p>
                        <p className="paragraph_subclause">
                            c. <span className="paragraph_clause-title"> Sobre o mínimo de faturamento: </span> Fica acordado entre as partes que haverá um  mínimo de faturamento para que o subsídio seja efetivo, e ele foi definido no valor  de R$ 1000,00 (mil reais) por mês para o MEI comum. Já para o MEI Caminhoneiro  o valor de faturamento mensal deve ser de R$2500,00 (dois mil e quinhentos reais).  Ou seja, ele deve emitir, obrigatoriamente uma Nota Fiscal com valores iguais ou  maiores aos estipulados acima.

                        </p>
                        <p className="paragraph_subclause">
                            d. <span className="paragraph_clause-title"> Sobre o não atingimento do mínimo: </span> Caso o usuário não atinja os valores mínimos  acordados, a guia DAS será descontada normalmente junto ao valor do programa  MotoMei. Este não atingimento não impede o usuário de gozar de nenhum  benefício do programa, implicando apenas o não subsídio da Guia. No mês que for  atingido o mínimo acordado, o pagamento da guia DAS terá o subsídio de 100%,  seguindo as regras estipuladas. Portanto, o usuário autoriza, no mês que não haja  o atingimento do mínimo estipulado, o desconto do valor juntamente ao valor do  programa, em valores somatórios e não excludentes entre si.

                        </p>


                        <p className="paragraph_clause">
                            7. <span className="paragraph_clause-title">Sobre os valores do Programa MotoMei: </span> O Programa MotoMei tem um custo semanal de  R$7,50 para o usuário. Este custo é referente à manutenção semanal do status “ATIVO” do  usuário no programa.
                        </p>

                        <p className="paragraph_subclause">
                            a. <span className="paragraph_clause-title">Da condição de Pagamento: </span> O usuário autoriza o pagamento ser realizado  diretamente no APP da BOX DELIVERY em caráter semanal, no valor de R$7,50. A  efetividade é a partir da primeira semana do mês seguinte à inclusão no programa.
                        </p>

                        <p className="paragraph_subclause-sub">
                            i. <span className="paragraph_clause-title">Falta de Saldo: </span> Caso não tenha saldo no momento do pagamento, o saldo  fica negativo no app do entregador até haver saldo. Não havendo saldo em  90 dias, contará como título em aberto.

                        </p>

                        <p className="paragraph_subclause-sub">
                            ii. <span className="paragraph_clause-title"> Sobre outra forma de pagamento: </span> Não está prevista nenhuma outra forma  de pagamento para a contratação deste serviço, que deve seguir,  obrigatoriamente as condições acima.

                        </p>

                        <p className="paragraph_subclause-sub">
                            iii. <span className="paragraph_clause-title"> Sobre não pagamento do subsídio: </span> Caso o usuário não se enquadre nas  condições de subsídio de impostos do programa MotoMei, ele será  descontado o valor da Guia Das correspondente ao valor vigente do mês,  sendo descontados em uma única parcela, em dia não estipulado dentro do  mês vigente.
                        </p>

                        <p className="paragraph_clause">
                            8. <span className="paragraph_clause-title">Sobre cancelamento: </span> O usuário que não desejar mais fazer parte do programa deve,  obrigatoriamente manifestar esse desejo para o e-mail contato@motomei.com com 30 dias  de antecedência à data que deseja finalizar a parceria, não incidindo ônus ou multa de  qualquer natureza ao usuário. Caso não haja esta manifestação, esse usuário continuará  ativo e sujeito às cobranças normais do programa por tempo indeterminado. A MotoMei  Contabilidade se reserva o direito de encerrar a parceria com qualquer usuário do serviço  sem necessidade de justificativa, respeitando apenas um prazo de 30 dias de antecedência  à finalização dos serviços. A comunicação do fim da parceria será pelo e-mail indicado em  cadastro.
                        </p>

                        <p className="paragraph_clause">
                            9. <span className="paragraph_clause-title">Sobre autorização de uso dos dados: </span>
                            Ao contratar este serviço o usuário autoriza a MotoMei a solicitar e utilizar os dados necessários a realização dos serviços aqui descritos e de qualquer portal necessário à  realização do serviço contábil de emissão de notas fiscais e abertura/consulta do MEI. O  usuário também concorda com o compartilhamento de quaisquer dados necessários a  realização do serviço aqui descrito, entre BOX DELIVERY e MOTOMEI CONTABILIDADE, com  o compromisso que esses dados serão guardados e utilizados apenas para este fim  específico, se valendo de todas as regras descritas na LGPD (Lei Geral de Proteção de Dados)  Lei Federal nº 13.709/2018.

                        </p>

                        <p className="paragraph_clause">
                            10. <span className="paragraph_clause-title"> Sobre direitos reservados: </span>
                            A MotoMei Contabilidade e a Box Delivery se reservam o direito de alterar qualquer uma  das cláusulas descritas anteriormente sem aviso prévio, estando essas condições ativas por  tempo indeterminado.
                        </p>

                    </div>
                </GeneralLayout >
            </div >
        </>
    )
}