import "../css/generalLayout.css"
import Logo from "../assets/motomei-logo.svg"

export default function GeneralLayout({ children }) {
    return (
        <>
            <header>
                <img src={Logo} alt="" />
            </header>
            <main>
                {children}
            </main>
        </>
    )
}