import "../css/plan.css"
import GeneralLayout from "../Layouts/GeneralLayout"
import Logo from "../assets/motomei-preto.svg"
import Card from "../components/Card"
import Active from "../assets/active.svg"
import whatsapp from '../assets/whatsapp.png'
import { useState } from "react"
import closeModal from '../assets/close-black.svg'
import { useNavigate } from "react-router-dom"

export default function Plan() {

    const navigate = useNavigate()
    const [modal, setModal] = useState(false)

    const toggleModal = () => {
        setModal(!modal);
    }

    let url = 'https://wa.me/5511910645514'
    function goWhatsapp() {
        window.open(url, "_blank")
    }

    const confirmModal = () => {
        navigate('/canceled-plan')
    }

    return (
        <GeneralLayout>
            <div className="plan__title">
                <p className="plan__subtitle">Confira informações sobre o seu plano</p>
            </div>
            <Card className="plan__card">
                <h4 className="card__h4">Plano MotoMei</h4>
                <div className="plan__active">
                    <img src={Active} alt="active" />
                    <p className="plan__active-text">Ativo</p>
                </div>
                <progress className="plan__progress" value="50" max="100"></progress>
                <p className="periodo">31/01/2023 a 07/02/2023</p>
            </Card>
            <a className="plan__a" onClick={toggleModal}>Cancelar meu plano</a>
            <div className="whatsapp">
                <p className="whatsapp__text">Alguma dúvida? Fale conosco</p>
                <button className="whatsapp__button" id='whatsapp-button' onClick={goWhatsapp}>
                    <img src={whatsapp} alt="whatsapp" />
                    Entre em contato
                </button>
            </div>
            <section className={modal ? 'show-modal' : 'hidden-modal'}>
                <div className="modal">
                    <div className="modal-close" onClick={toggleModal}>
                        <img src={closeModal} alt="" />
                    </div>
                    <h5 className="modal-title">Tem certeza que deseja cancelar seu plano?</h5>
                    <div className="modal-options">
                        <button className="modal-options__button" onClick={toggleModal}>Cancelar</button>
                        <button className="modal-options__button"
                            onClick={confirmModal}>Confirmar</button>
                    </div>
                </div>
            </section>
        </GeneralLayout>
    )
}