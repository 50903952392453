import '../css/concluido.css';
import { Button } from '../components/Button';
import GeneralLayout from "../Layouts/GeneralLayout"
import iconConcluido from '../assets/concluido.png'
import { useNavigate } from 'react-router-dom';

export default function Concluido() {
  
  const navigate = useNavigate()

  const login = () => {
    navigate('/')
  }

  return (
    <div className='wrapperGeneralLayout'>
      <GeneralLayout>
        <img src={iconConcluido} alt="" className='icon-person' />
        <div className='container-concluido'>
          <h1>Cadastro concluído!</h1>
          <article>
            <p>
            Por favor, aguarde. Seu cadastro já foi recebido pela nossa equipe e está sendo analisado.
            </p>
            <p>
            Em breve você será notificado sobre o status do seu cadastro.
            </p>
          </article>
        </div>
      </GeneralLayout>
        <Button name="Finalizar" onClick={login}/>
    </div>
  )
}
