import '../css/cardWarning.css'

export function CardWarning(props) {
  return (
    <>
      <div className="container_warning" style={{ overflow: 'hidden' }}>
        <div className="warning">
          <img src="/warning.svg" alt="Minha Imagem" className='warning_icon' />
        </div>
        <div className='text_warning'>
          <p className='text_warning-text'>{props.texto}</p>
        </div>
      </div>
    </>
  );
}