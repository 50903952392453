import Check from "../assets/check.svg"
import "../css/cardBenefits.css"

export default function CardBenefits(props){
    return (
        <div className="card-benefits">
            <img src={Check} alt="check" />
            <p className="card-benefits__text">{props.text}</p>
        </div>
    )
}