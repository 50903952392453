import '../css/buttonFooter.css'

export function Button(props) {
    return (
        <>
            <div className="button-footer">
                <button
                    type="submit"
                    id={props.id}
                    onClick={props.onClick}
                    className='button-footer__button'
                    form={props.form}
                    {...props}
                >
                    {props.name}
                </button>
            </div>
        </>
    )
}